/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MailIcon from '@mui/icons-material/Mail';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import GroupIcon from '@mui/icons-material/Group';
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import routes from "routes";
import Sidenav from "examples/Sidenav";
import { MenuItem, Select, Tooltip, useMediaQuery } from "@mui/material";
import Inbox from "components/Inbox/Inbox";
import { useTranslation } from "react-i18next";

function DashboardNavbar({ absolute, light, isMini }) {

  const {i18n} = useTranslation()
  const isMedium = useMediaQuery("(min-width:768px)");
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = [(routes.find(item => item.route == `/${window.location.pathname.split("/")[1]}`)?.name)];
  const profile = JSON.parse(localStorage.getItem('company'))?.photo

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const changeLanguage = (lang) => {
    window.location.reload()
    localStorage.setItem('langue', lang)
  }

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    fontSize : "30px !important"
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      {
        !isMedium ? 
        <div className="w-full">
        <Select
          className="w-fit bg-transparent mb-3"
          onChange={(e) => changeLanguage(e.target.value)}
          value={localStorage.getItem('langue') || 'fr'}
          sx={{
              fontSize : '17px',
              float : 'right'
          }}
        >
          <MenuItem value="fr">Fr</MenuItem>
          <MenuItem value="en">En</MenuItem>
        </Select>
        </div> : null
      }
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1] === 'bw-admin-wdash' ? 'Tableau de board' : route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              {
                isMedium ? 
                <Select
                  className="w-fit bg-transparent mr-3"
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={localStorage.getItem('langue') || 'fr'}
                  sx={{
                      fontSize : '17px'
                  }}
                >
                  <MenuItem value="fr">Fr</MenuItem>
                  <MenuItem value="en">En</MenuItem>
                </Select> : null
              }
              <Link to="/profile">
                {
                  profile ? 
                    <IconButton sx={{
                      padding : '0 !important',
                      width : '30px',
                      heigth : '30px',
                    }}>
                      <img className="w-full h-full rounded-full" src={profile} alt="profile" />
                    </IconButton>
                    :
                    <IconButton size="small" disableRipple>
                      <Icon sx={iconsStyle}>account_circle</Icon>
                    </IconButton>
                }
              </Link>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {
                !JSON.parse(localStorage.getItem('isAdmin')) && (
                  <>
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    onClick={handleMiniSidenav}
                  >
                    <Inbox iconsStyle={iconsStyle} />
                  </IconButton>
                  <Link
                    to='/visiteurs'
                  >
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      onClick={handleMiniSidenav}
                    >
                      <Tooltip title="visiteurs">
                        <GroupIcon sx={iconsStyle} />
                      </Tooltip>
                    </IconButton>
                  </Link>
                  </>
                )
              }
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <Sidenav
            brandName="B2B Maroc"
            routes={routes}
        />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
