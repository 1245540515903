import { Box } from '@mui/system';
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ErrorPage = () => {

    const {t} = useTranslation()
    const navigate = useNavigate();
    const {code} = useParams()
    
    const redirect = (e) => {

        e.preventDefault()
        const previousPath = localStorage.getItem('previousPath') || '/';
        localStorage.removeItem('previousPath'); 
        navigate(previousPath);

    }

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <section className="page_404">
            
            <div className="container">
                <div className="row"> 
                <div className="col-sm-12 ">
                <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                <h1 className="text-center ">{code}</h1>
                
                
                </div>
                
                <div className="contant_box_404">
                <h3 className="h2">
                    {
                        code == 500 && t('errorPages.500.title')
                    }
                    {
                        code == 403 && t('errorPages.403.title')
                    }
                </h3>
                
                <p className="mb-4">
                    {
                        code == 500 && t('errorPages.500.description')
                    }
                    {
                        code == 403 && t('errorPages.403.description')
                    }
                </p>
                
                <Box className="flex justify-center items-center gap-5">
                <MDButton
                    onClick={(e) => redirect(e)}
                    rel="noreferrer"
                    variant="gradient"
                    color="info"
                    >
                    {
                        t('errorPages.buttons.retry')
                    }
                </MDButton>
                </Box>

            </div>
                </div>
                </div>
                </div>
            </div>
        </section>
    </DashboardLayout>
  )
}

export default ErrorPage