export const Logo = 'https://i.ibb.co/943Q3Wd/Screenshot-2024-12-07-121353.png';
export const Logo_white = 'https://i.ibb.co/5KxWrBq/Logo-white.png'
export const noImage = 'https://i.ibb.co/99r59dC/no-image.png';
export const Rectangle221 = 'https://i.ibb.co/K7DxC0S/Rectangle221.png';
export const B2BProduct ='https://i.ibb.co/WgPHcmP/B2-B-Product.png';
export const B2B = 'https://i.ibb.co/NrY1PtS/B2B.png';
export const Left = 'https://i.ibb.co/vJ5tZTD/left.png';
export const LocationIcon = 'https://i.ibb.co/XJFy8KQ/location.png';
export const login = 'https://i.ibb.co/mSnPKYb/Login.png';
export const Right = 'https://i.ibb.co/x27xNJm/right.png';
export const UploadIcon = 'https://i.ibb.co/VxP2kkL/upload.png';
export const silver = 'https://i.ibb.co/kgqRG8W/silver.png';
export const gold = 'https://i.ibb.co/LxyW6WS/golden.png';
export const diamant = 'https://i.ibb.co/q0bSxSd/diamont.png';
export const premium = 'https://i.ibb.co/CnQGjNn/premium.jpg';
