import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import ContactModal from 'components/Modals/commands/ContactModal';
import MailIcon from '@mui/icons-material/Mail';
import { axiosClient } from 'Api/axoisClient';
// import window from 'Api/echo';

const Inbox = ({iconsStyle = null}) => {

    const [open, setOpen] = useState(false)
    const [NewMessage, setNewMessage] = useState(false)
    const [MessagesCount, setMessagesCount] = useState(0)

    const my_id = JSON.parse(localStorage.getItem('user'))?.id;
    
    // useEffect(() => {

    //     const chatChannel = window.Echo.private('new_message.' + my_id);

    //     chatChannel.listen('.NewMessage', (data) => {
            
    //         setNewMessage(!open ? true : false)

    //     });

    //     return () => {
    //         chatChannel.stopListening('.NewMessage');
    //         window.Echo.leaveChannel('private-new_message.' + my_id);
    //     };

    // }, [])

    useEffect(() => {

        (async () => {
            if(
                window.location.pathname != '/errors/500' &&
                window.location.pathname != '/errors/403' 
            ){
                try {
                    
                    const res = await axiosClient.get('/messages/check');
                    
                    if(res.status === 200){
    
                        if(res.data > 0){
                            setNewMessage(!open ? true : false)
                            setMessagesCount(res.data)
                        }
    
                    }
    
                } catch (error) {
                    console.log(error);
                }
            }


        })()

    }, [])

    useEffect(() => {

        if(open && NewMessage) {
            setNewMessage(false)
        }

    }, [open])

    useEffect(() => {

        if(open) {
            setNewMessage(false)
        }

    }, [NewMessage])

    if (localStorage.getItem('isAdmin') == 'true') return null; 
        
  return (
    <>
        {
            open && <ContactModal userId="null" setOpen={setOpen} open={open} />
        }
        <div onClick={() => setOpen(true)} className='relative'>
            <MailIcon sx={iconsStyle} />
            {
                NewMessage && <div className="absolute top-0 rounded-full text-white bg-red-500 -right-1 flex justify-center items-center p-2 text-[13px] h-[15px] w-[15px]"><span>{MessagesCount}</span></div>
            }
        </div> 
    </>
  )
}

export default Inbox