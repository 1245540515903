import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { setDarkMode } from "context";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

export default function App() {
  const { isAdmin, isAuthenticated } = useSelector((state) => state.auth);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const isLarge = useMediaQuery("(min-width:1200px)");
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('langue', lng);
  };

  // Initialize language from localStorage or default to 'fr'
  useEffect(() => {
    const savedLanguage = localStorage.getItem('langue');
    if (!savedLanguage) {
      // If no language is saved, set to 'fr' as default
      changeLanguage('fr');
    }
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, isAdmin) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && (route?.belongToAdmin === isAdmin || route.belongToAdmin == undefined)) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      // onClick={handleConfiguratorOpen}
      onClick={handleDarkMode}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
      {
        darkMode ? <LightModeIcon sx={{fontSize : '30px !important'}} /> : <DarkModeIcon sx={{fontSize : '30px !important'}} />
      }
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && isAuthenticated && (
        <>
        {
          isLarge && (
            <Sidenav
              color={sidenavColor}
              brandName={t('brandName')}
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )
        }
        
        {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes, isAdmin)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
