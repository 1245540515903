import { Box, Card, Grid, Pagination, Typography, useMediaQuery  } from '@mui/material'
import CircularProgress from "@mui/material/CircularProgress";

import { axiosClient } from 'Api/axoisClient'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";

const Views = () => {

    const { t } = useTranslation();

    // set true in mobile screan
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    // style of pagination
    const style = {
      '& .MuiPaginationItem-root': {
          color: '#abaebe',
          borderColor: '#abaebe', 
          fontSize: isSmallScreen ? '14px' : '16px',
          borderRadius : '10px',
          padding : '4px'
      },
      '& .MuiPaginationItem-root:hover': {
        backgroundColor: '#439df02b',
        color: '#449ef0',
        borderColor: '#449ef0',
      },
      '& .Mui-selected': {
          backgroundColor: '#439df02b !important',
          color: '#449ef0',
          borderColor: '#449ef0',
      },
      '& .Mui-selected:hover': {
        backgroundColor: '#439df02b'
      }
    }

    // colums of table.
    const columns = [
        { Header: t("views.columns.num"), accessor: "Num", align: "left", width:'4%' },
        { Header: t("views.columns.name"), accessor: "Name", align: "left" },
        { Header: t("views.columns.date"), accessor: "Date", align: "left" },
        { Header: t("views.columns.action"), accessor: "Action", align: "center", width:'5%' },
    ];

    const [page, setPage] = useState(1);
    const [TotalPages, setTotalPages] = useState(0);
    const [rows, setRows] = useState([]);

    const [Reload, setReload] = useState(false);
    const [loading, setLoding] = useState(true);


    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {

        (async ()=>{
  
          try {
  
              const res = await axiosClient.get(`/views/${page}`, headers)
  
            if(res.status === 200 && res.data.views?.length > 0){
              setRows(
                
                res?.data?.views?.map(item => {
                  return {
                    Num: (
                        <MDTypography component="a" href="#" variant="caption" fontSize={15} color="text" fontWeight="medium">
                          {item?.client_company}
                        </MDTypography>
                    ),
                    Name: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        fontSize={15}
                        color="text"
                        fontWeight="medium"
                      >
                        {item?.name}
                      </MDTypography>
                    ),
                    Date: (
                      <MDTypography component="a" href="#" variant="caption" color="text" fontSize={15} fontWeight="medium">
                        {item?.date || "-"}
                      </MDTypography>
                    ),
                    Action: (
                        <Box className='flex items-center gap-2'>
                            <Typography target="_blank"  className='w-3/4' href={`${process.env.REACT_APP_MARKET_PATH}/fournisseurs/${item?.client_company}`} component="a">
                                <InfoIcon className='text-green-500'/>
                            </Typography>
                        </Box>
                      ),
                  }
                })
              )

              setTotalPages(res.data.total_pages);
  
            }else{
  
              setRows([])
            }
  
          }catch (rej){
  
            console.log(rej);
            setRows([])

          }

          setLoding(false);

        })()
  
    }, [Reload])

    const handlePageChange = (event, value) => {

        setPage(value);
        setReload(!Reload)

    };

    const clear = async (e) => {
        e.preventDefault()
        try {

            const res = await axiosClient.delete('/views/clear')

            if(res.status === 200){
                Swal.fire({
                    title: t("views.messages.clearSuccess.title"),
                    text: t("views.messages.clearSuccess.text"),
                    icon: "success",
                });

                setReload(pre => !pre)

            }

        } catch (err) {
            Swal.fire({
                title: t("views.messages.clearError.title"),
                text: t("views.messages.clearError.text"),
                icon: "error",
                confirmButtonText: t("views.buttons.retry"),
            });
        }

    }

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <Grid item  xs={12}>
            <Card className='mt-14'>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("views.title")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <Box className='flex justify-end px-[16px] py-2 items-center'>
                  <button onClick={(e) => clear(e)} className='bg-second px-2 text-sm py-2 hover:opacity-85 text-white font-semibold rounded-lg'>
                    {t("views.buttons.clear")}
                  </button>
                </Box>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {
                  loading ? (
                    <MDBox
                    mb={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color='info' /> 
                  </MDBox>
                    ): rows.length === 0 && <p className="text-center mb-10 w-full">{t("views.messages.noVisitors")}</p>
                }
                {
                  rows.length > 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '20px 0',
                        padding: '0 10px', // Adds some padding for smaller screens
                        width: '100%',
                        boxSizing: 'border-box', // Ensures padding does not affect the total width
                    }}>
                        <Pagination
                            count={TotalPages}
                            page={page}
                            onChange={handlePageChange}
                            sx={style}
                            size={isSmallScreen ? 'small' : 'large'}
                            variant="outlined"
                        />
                    </div>
                  )
                }
              </MDBox>
            </Card>
        </Grid>
    </DashboardLayout>
  )
}

export default Views