import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Tooltip
} from "@mui/material";
import { axiosClient } from "Api/axoisClient";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PlanModal from "components/Modals/commands/PlanModal";
import { useTranslation } from "react-i18next";

const Plans = () => {
  const {t} = useTranslation();
  // colums of table.
  const columns = [
    { Header: t('plans.columns.type'), accessor: "Type", align: "left" },
    { Header: t('plans.columns.maxAnnouncements'), accessor: "max_announcements", align: "center" },
    { Header: t('plans.columns.maxUpdates'), accessor: "max_update", align: "center" },
    { Header: t('plans.columns.action'), accessor: "action", align: "center" },
  ];

  const [rows, setRows] = useState([]);

  const [Reload, setReload] = useState(false);

  const [planInfo, setplanInfo] = useState({});

  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setisLoading(true)
      try {
        const res = await axiosClient.get(
          `/plans`
        );

        if (res.status === 200 && res.data?.length > 0) {
          setRows(
            res?.data?.map((item) => {
              return {
                Type: (
                  <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    fontSize={15}
                    color="text"
                    fontWeight="medium"
                  >
                    {item?.type}({item?.id})
                  </MDTypography>
                ),
                max_announcements: (
                  <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    fontSize={15}
                    color="text"
                    fontWeight="medium"
                  >
                    {item?.max_announcements ?? t('plans.unlimited')}
                  </MDTypography>
                ),
                max_update: (
                    <MDTypography
                      component="a"
                      href="#"
                      variant="caption"
                      fontSize={15}
                      color="text"
                      fontWeight="medium"
                    >
                      {item?.max_update ?? t('plans.unlimited')}
                    </MDTypography>
                  ),
                action: (
                  <Box className="flex items-center gap-2">

                    <Tooltip title={t('plans.tooltips.information')}>
                      <EditIcon
                        className="text-blue-500"
                        sx={{ fontSize: "18px !important" }}
                        onClick={(e) => ShowCompany(e, item?.id)}
                      />
                    </Tooltip>

                    {/* <Tooltip title="information">
                      <DeleteIcon
                        className="text-red-500"
                        sx={{ fontSize: "18px !important" }}
                        onClick={(e) => Delete(e, item?.id)}
                      />
                    </Tooltip> */}

                  </Box>
                ),
              };
            })
          );
        } else {
          setRows([]);
        }
      } catch (rej) {
        setRows([]);
      }
      setisLoading(false)
    })();
  }, [Reload]);

  useEffect(()=>{

    if(!openCreate || !openUpdate){

      setplanInfo({})

    }

  }, [openCreate, openUpdate])

  const ShowCompany = async (e, id) => {

    try {
      const res = await axiosClient.get(`/plans/${id}`);

      if (res.status === 200) {
        setplanInfo(res.data);
        setOpenUpdate(true);
      }
    } catch (err) {
      console.log(err);
    }

  };

  const Delete = (e, id) => {

    e.preventDefault()

    Swal.fire({
      title: t('plans.deleteConfirmation.title'),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: t('plans.deleteConfirmation.confirmButton'),
      icon: "warning",
    }).then(async (response) => {

      if (response.isConfirmed) {

        try {
          const res = await axiosClient.delete(`/plans/${id}`);

          if (res.status === 200) {
            setReload(!Reload);
          }

        } catch (err) {
          console.log(err);
        }

      }

    })

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {openCreate && (
        <PlanModal 
          plan={planInfo} 
          setReload={setReload}
          action="create" 
          open={openCreate}
          setOpen={setOpenCreate} 
        />
      )}

      {openUpdate && (
        <PlanModal 
          plan={planInfo} 
          setReload={setReload}
          action="update" 
          open={openUpdate} 
          setOpen={setOpenUpdate} 
        />
      )} 

      <Grid item xs={12}>
        <Card className="mt-14">
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Packs
            </MDTypography>
          </MDBox>
          <MDBox pt={1}>
{/* 
            <Box className='flex justify-end px-[16px] py-2 items-center'>
              
              <button onClick={() => setOpenCreate(true)} className='bg-second px-2 text-sm py-2 hover:opacity-85 text-white font-semibold rounded-lg'>
                Ajouter plan
              </button>

            </Box> */}

            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={{
                defaultValue: 20,
                entries: [],
              }}
              showTotalEntries={false}
              noEndBorder
            />

            {
                  isLoading ? (
                    <MDBox
                    mb={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color='info' /> 
                  </MDBox>
                    ): rows.length === 0 && <p className="text-center mb-10 w-full">No plans</p>
                }

          </MDBox>
        </Card>
      </Grid>
    </DashboardLayout>
  );
};

export default Plans;
